import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const state = {
    headIndex: 0,
    isShow: true,
    worksList: [
        {
            img: require("@/assets/img/app-download.png"),
            title: "App Name",
            time: "2024-03-05",
        },
        {
            img: require("@/assets/img/app-download.png"),
            title: "App Name",
            time: "2024-03-05",
        },
        {
            img: require("@/assets/img/app-download.png"),
            title: "App Name",
            time: "2024-03-05",
        },
        {
            img: require("@/assets/img/app-download.png"),
            title: "App Name",
            time: "2024-03-05",
        },
    ],
    newsList: [
        {
            title: "",
            time: "2024-03-05",
            desc: "",
        },
        {
            title: "",
            time: "2024-03-05",
            desc: "",
        },
        {
            title: "",
            time: "2024-03-05",
            desc: "",
        },
        {
            title: "",
            time: "2024-03-05",
            desc: "",
        },
    ],
    navList: [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Products",
            path: "/works",
        },

        {
            name: "About Us",
            path: "/aboutUs",
        },
        {
            name: "Contact Us",
            path: "/contactUs",
        },
        {
            name: "Join Us",
            path: "/attractPeople",
        },
        {
            name: "Terms of Service",
            path: "/terms",
        },
        {
            name: "Privacy Policy",
            path: "/policy",
        },
    ],
};

const mutations = {
    SET_INDEX(state, data) {
        state.headIndex = data;
    },
    SET_HEADFOOTERSHOW(state, data) {
        state.isShow = data;
    },
};

const actions = {
    set_index({ commit }, data) {
        commit("SET_INDEX", data);
    },
    set_headFooterShow({ commit }, data) {
        commit("SET_HEADFOOTERSHOW", data);
    },
};

const modules = {};

const store = new Vuex.Store({
    state,
    actions,
    mutations,
    modules,
});

export default store;
