<template>
    <header
        :class="[
            'header-container',
            'display-align-center',
            isScrolled ? 'set-bg' : '',
        ]"
    >
        <div class="display-between-center header-wrap">
            <div class="logo-style"></div>
            <ul class="display-between-center">
                <li
                    v-for="(item, index) in $store.state.navList"
                    :key="index"
                    :class="[
                        'nav-li',
                        $store.state.headIndex == index ? 'is-select' : '',
                    ]"
                    @click="goToPage(item, index)"
                >
                    {{ item.name }}
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
export default {
    name: "headerComponent",
    data() {
        return {
            scrollTop: 0,
            isScrolled: false,
            isDetail: true,
        };
    },
    created() {
        // const headIndex = localStorage.getItem("headIndex");
        // if (headIndex >= 0) {
        //     this.$store.dispatch("set_index", headIndex);
        // }
        // console.log(this.$route);
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    watch: {
        $route(to, from) {
            if (Object.keys(to.query).length > 0) {
                this.isDetail = true;
                this.isScrolled = true;
            } else {
                this.isDetail = false;
                this.isScrolled = false;
            }
        },
    },
    methods: {
        goToPage(item, index) {
            this.$store.dispatch("set_index", index);
            localStorage.setItem("headIndex", index);
            this.$router.push({
                path: item.path,
            });
        },
        handleScroll() {
            if (this.isDetail) {
                this.isScrolled = true;
                return;
            }
            this.scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
            this.isScrolled = this.scrollTop > 100; //
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-container {
    width: 100%;
    position: fixed;
    height: 100px;
    top: 0;
    box-sizing: border-box;
    padding: 10px 0;
    z-index: 9999;
    transition: 0.2s;
}

.set-bg {
    background: #222222;
    height: 80px;
}

.header-wrap {
    width: 1170px;
    margin: 0 auto;
}

.logo-style {
    width: 160px;
    height: 80px;
    background: url("@/assets/img/logo.png") no-repeat center;
    background-size: 100%;
}

.nav-li {
    font-size: 14px;
    color: white;
    margin: 0 20px;
    cursor: pointer;
    transition: 0.3s;
}

.nav-li:hover {
    color: #09f;
}

.is-select {
    color: #09f;
}
</style>
