<template>
    <footer class="footer-container display-center-center">
        <ul class="footer-wrap">
            <li>
                Address：Hunan Changsha Xiangyu International Center / support@pzmobile.com /
            </li>
            <li>Copyright © 2024</li>
            <!-- <li>Friendship link：</li> -->
        </ul>
    </footer>
</template>

<script>
export default {
    name: "footerComponent",
    data() {
        return {};
    },
};
</script>

<style scoped>
.footer-container {
    width: 100%;
    background: #222222;
    box-sizing: border-box;
    padding: 20px 0px;
    height: 130px;
}

.footer-wrap {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
}

.footer-wrap > li {
    margin: 10px 0;
}
</style>