import Vue from "vue";
import Router from "vue-router";
import store from "@/store/index.js";

Vue.use(Router);

export const constantRoutes = [
    {
        path: "/",
        component: () => import("@/views/home/index.vue"),
        meta: {
            title: "Home",
        },
    },
    {
        path: "/works",
        component: () => import("@/views/works/index.vue"),
        meta: {
            title: "Product",
        },
    },
    {
        path: "/aboutUs",
        component: () => import("@/views/aboutUs/index.vue"),
        meta: {
            title: "AboutUs",
        },
    },
    {
        path: "/contactUs",
        component: () => import("@/views/contactUs/index.vue"),
        meta: {
            title: "ContactUs",
        },
    },
    {
        path: "/attractPeople",
        component: () => import("@/views/attractPeople/index.vue"),
        meta: {
            title: "JoinUs",
        },
    },
    {
        path: "/terms",
        component: () => import("@/views/terms/index.vue"),
        meta: {
            title: "Terms of Service",
        },
    },
    {
        path: "/policy",
        component: () => import("@/views/policy/index.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
    {
        path: "/app/terms",
        component: () => import("@/views/app/terms.vue"),
        meta: {
            title: "Terms of Service",
        },
    },
    {
        path: "/app/policy",
        component: () => import("@/views/app/policy.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
    {
        path: "/app/es/terms",
        component: () => import("@/views/app/esterms.vue"),
        meta: {
            title: "Terms of Service",
        },
    },
    {
        path: "/app/es/policy",
        component: () => import("@/views/app/espolicy.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
    {
        path: "/app/en/terms",
        component: () => import("@/views/app/enterms.vue"),
        meta: {
            title: "Terms of Service",
        },
    },
    {
        path: "/app/en/policy",
        component: () => import("@/views/app/enpolicy.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
    {
        path: "/app/ja/terms",
        component: () => import("@/views/app/jaterms.vue"),
        meta: {
            title: "Terms of Service",
        },
    },
    {
        path: "/app/ja/policy",
        component: () => import("@/views/app/japolicy.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
    {
        path: "/app/th/terms",
        component: () => import("@/views/app/thterms.vue"),
        meta: {
            title: "Terms of Service",
        },
    },
    {
        path: "/app/th/policy",
        component: () => import("@/views/app/thpolicy.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
    {
        path: "/app/pt/terms",
        component: () => import("@/views/app/ptterms.vue"),
        meta: {
            title: "Terms of Service",
        },
    },
    {
        path: "/app/pt/policy",
        component: () => import("@/views/app/ptpolicy.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
    {
        path: "/app/ko/terms",
        component: () => import("@/views/app/koterms.vue"),
        meta: {
            title: "Terms of Service",
        },
    },
    {
        path: "/app/ko/policy",
        component: () => import("@/views/app/kopolicy.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
    {
        path: "/app/hi/terms",
        component: () => import("@/views/app/hiterms.vue"),
        meta: {
            title: "Terms of Service",
        },
    },
    {
        path: "/app/hi/policy",
        component: () => import("@/views/app/hipolicy.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
    {
        path: "/app/fr/terms",
        component: () => import("@/views/app/frterms.vue"),
        meta: {
            title: "Terms of Service",
        },
    },
    {
        path: "/app/fr/policy",
        component: () => import("@/views/app/frpolicy.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
    {
        path: "/app/de/terms",
        component: () => import("@/views/app/determs.vue"),
        meta: {
            title: "Terms of Service",
        },
    },
    {
        path: "/app/de/policy",
        component: () => import("@/views/app/depolicy.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
    {
        path: "/app/it/terms",
        component: () => import("@/views/app/itterms.vue"),
        meta: {
            title: "Terms of Service",
        },
    },
    {
        path: "/app/it/policy",
        component: () => import("@/views/app/itpolicy.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
];

const createRouter = () =>
    new Router({
        mode: "history", // require service support
        scrollBehavior: () => ({
            y: 0,
        }),
        routes: constantRoutes,
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

//
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    let headIndex = constantRoutes.findIndex((item) => {
        return item.path == to.path;
    });
    if (to.path.includes("/app")) {
        store.dispatch("set_headFooterShow", false);
    }
    if (headIndex == -1) {
        store.dispatch("set_index", 0);
        next("/");
        return;
    } else {
        store.dispatch("set_index", headIndex);
    }
    next();
});

export default router;
