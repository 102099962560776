<template>
    <div id="app">
        <headerComponent v-if="$store.state.isShow"></headerComponent>
        <router-view></router-view>
        <footerComponent v-if="$store.state.isShow"></footerComponent>
    </div>
</template>

<script>
import headerComponent from "@/components/header.vue";
import footerComponent from "@/components/footer.vue";

export default {
    name: "App",
    components: {
        headerComponent,
        footerComponent,
    },
    mounted() {
        // const headIndex = localStorage.getItem("headIndex");
        // if (headIndex >= 0) {
        //     this.$store.dispatch("set_index", headIndex);
        // }
    },
};
</script>
