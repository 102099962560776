var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:[
        'header-container',
        'display-align-center',
        _vm.isScrolled ? 'set-bg' : '',
    ]},[_c('div',{staticClass:"display-between-center header-wrap"},[_c('div',{staticClass:"logo-style"}),_c('ul',{staticClass:"display-between-center"},_vm._l((_vm.$store.state.navList),function(item,index){return _c('li',{key:index,class:[
                    'nav-li',
                    _vm.$store.state.headIndex == index ? 'is-select' : '',
                ],on:{"click":function($event){return _vm.goToPage(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }